@import '../../scss/default/color_variable';
.cs-iconbox.cs-style1 {
  max-width: 350px;
  margin: auto;
  .cs-iconbox_icon {
    margin-bottom: 25px;
    display: flex; 
    align-items: center;
    justify-content: center; 
    
  }
  .cs-iconbox_title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .cs-iconbox_subtitle {
    line-height: 1.75em;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    .cs-iconbox_title {
      font-size: 26px;
      margin-bottom: 10px;
    }
  }
}
.cs-iconbox.cs-style2 {
  max-width: 350px;
  margin: auto;
  .cs-iconbox_icon {
    margin-bottom: 25px;
  }
  .cs-iconbox_title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .cs-iconbox_subtitle {
    line-height: 1.75em;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    .cs-iconbox_title {
      font-size: 26px;
      margin-bottom: 10px;
      margin-bottom: 20px;
    }
  }
}

.cs-iconbox_4_wrap {
  display: grid;
  grid-gap: 65px 135px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1400px) {
    grid-gap: 50px 100px;
  }
  @media (max-width: 1199px) {
    grid-gap: 40px;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.cs-iconbox.cs-style4 {
  .cs-iconbox_icon {
    margin-bottom: 22px;
  }
  .cs-iconbox_title {
    font-size: 30px;
    line-height: 1.37em;
    margin-bottom: 20px;
    @media (max-width: 1400px) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }
  .cs-iconbox_subtitle {
    line-height: 1.75em;
    margin-bottom: 30px;
    @media (max-width: 1400px) {
      margin-bottom: 22px;
    }
  }
  .cs_iconbox_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;  /* Center horizontally */
    color: #fff;
    svg {
      transition: inherit;
      display: flex; 
      align-items: center;
      justify-content: center; 
    }
    span {
      margin-right: 0px;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      color: $accent;
      span {
        margin-right: 10px;
        max-width: 100px;
      }
    }
  }
}
