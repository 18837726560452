@tailwind base;
@tailwind components;
@tailwind utilities;
@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/video-modal";
@import "common/sidebar";


