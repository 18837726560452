/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #257cd9;
}
$white: #fff;
$black: #161616;
$primary: #fefefe;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4d4d4d;
$gray: #181818;
$red : #FF0000;
$accent: var(--accent);

.background-red {
  color: $red; // Apply the red color as a background
}